﻿@import "bootstrap-variables";
@import "variables";
@import "mixins";
@import "mixins/buttons";

/*For bright green/blue/orange/red backgrounds, force link color to be white to prevent eye strain */
.bg-danger, .bg-info, .bg-warning, .bg-success {
    a, i {
        color: white !important;
    }
}

/*Button override so buttons look like bootstraps by default */

input[type=submit]:not(.btn):not(.e-btn):not(.e-prev):not(.e-next):not(.list-group-item),
button:not(.btn):not(.navbar-toggle):not(.iziToast-close):not(.e-btn):not(.e-prev):not(.e-next):not(.list-group-item),
input[type=button]:not(.btn):not(.e-btn):not(.e-prev):not(.e-next):not(.list-group-item) {
    /* Start copy of .btn class */
    display: inline-block;
    margin-bottom: 0; // For input.btn
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
    @include user-select(none);

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            @include tab-focus;
        }
    }

    &:hover,
    &:focus,
    &.focus {
        color: $btn-default-color;
        text-decoration: none;
    }

    &:active,
    &.active {
        outline: 0;
        background-image: none;
        @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: $cursor-disabled;
        @include opacity(.65);
        @include box-shadow(none);
    }
    /* End copy of .btn class */
    /* Start copy of .btn-default class */
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    /* End copy of .btn-default class */
    /* Start copy of .btn-sm class */
    @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
    /* End copy of .btn-sm class */
}

a.btn, a.btn:hover, a.btn:visited {
    color: #333333;

    &.btn-primary {
        color: #ffffff;
    }
}

a.btn-link, a.btn-link:hover, a.btn-link:visited {
    color: $content_link_color;
}

.btn.dropdown span,
.button.dropdown span {
    display: block;
    float: left;
    white-space: nowrap;

    &:after, &:after {
        font-family: "portal";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .5em;
        color: #757575;
        content: '\e803';
        font-size: 14px;
        position: relative;
        top: 1px;
    }
}
/*End button override*/

/*Table override so tables without the table class still looks like bootstrap.*/


/*table:not(.table) {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}*/

/*End table override*/

.container {
    width: 980px;

    .row {
        > div {
            > h2 {
                margin-top: 0;
            }
        }

        &.row-empty {
            min-height: 20px;
        }
    }
}

.modal-dialog {
    text-align: left;
}

.halfWidth {
    width: 50% !important;
}

.alert {
    .validation-summary-errors {
        ul {
            margin-bottom: 0;
        }
    }
}

ul.list-group, ul.nav-tabs, ul.nav-pills {
    margin-left: 0 !important;
}

p.form-control-static {
    margin-top: 0;
}

.nav > li > button {
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
}

.btn {
    &.btn-primary, &.btn-success, &.btn-info, &.btn-warning, &.btn-danger {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.btn {

    @extend .btn-sm;

    &.btn-default {
        border-color: #ccc !important;
    }

    &.btn-primary {
        @include button-variant($brand-primary);
    }

    &.btn-secondary {
        @include button-variant(#6c757d);
    }

    &.btn-success {
        @include button-variant($brand-success);
    }

    &.btn-info {
        @include button-variant($brand-info);
    }

    &.btn-warning {
        @include button-variant($brand-warning);
    }

    &.btn-danger {
        @include button-variant($brand-danger);
    }

    &.btn-light {
        @include button-variant(#f8f9fa);
        color: #212529 !important;
    }

    &.btn-dark {
        @include button-variant(#343a40);
    }

    &.btn-outline-default {
        @extend .btn-default;
    }

    &.btn-outline-primary {
        @include button-outline-variant($brand-primary, #fff, $btn-primary-bg, $btn-primary-border);
    }

    &.btn-outline-secondary {
        @include button-outline-variant(#6c757d, #fff, #6c757d, #6c757d);
    }

    &.btn-outline-success {
        @include button-outline-variant($brand-success, #fff, $btn-success-bg, $btn-success-border);
    }

    &.btn-outline-info {
        @include button-outline-variant($brand-info, #fff, $btn-info-bg, $btn-info-border);
    }

    &.btn-outline-warning {
        @include button-outline-variant($brand-warning, #fff, $btn-warning-bg, $btn-warning-border);
    }

    &.btn-outline-danger {
        @include button-outline-variant($brand-danger, #fff, $btn-danger-bg, $btn-danger-border);
    }

    &.btn-outline-light {
        @include button-outline-variant(#f8f9fa, #f8f9fa, #f8f9fa, #f8f9fa);
    }

    &.btn-outline-dark {
        @include button-outline-variant(#343a40, #fff, #343a40, #343a40);
    }
}

.btn.btn-link {
    &.text-muted {
        color: $text-muted;
    }

    &.text-primary {
        color: $brand-primary;
    }

    &.text-success {
        color: $brand-success;
    }

    &.text-info {
        color: $brand-info;
    }

    &.text-warning {
        color: $brand-warning;
    }

    &.text-danger {
        color: $brand-danger;
    }
}

.disabled-tooltip-wrapper {
    cursor: not-allowed;
    display: inline-block;

    [disabled], .disabled {
        pointer-events: none;
    }
}

.imdAutoComplete span.label-default {
    color: #ffffff;
}

.dropdown-menu {
    
    // Buttons within the dropdown menu
    > li > button {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines

        // Additional styles needed to override regular button styling
        width: 100%;
        text-align: left;
    }
}

// Hover/Focus state
.dropdown-menu > li > button {
    &:hover,
    &:focus {
        text-decoration: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
    }
}

.EasyMDEContainer .editor-toolbar button {
    color: inherit !important;
    background-color: transparent !important;
}
