$rootPath: "" !default;

$image_base: "#{$rootPath}/assets/theme/v000/images" !default;
$test: red !default;

$content_link_color: #337ab7;

$content_box_background_color: #f5f5f5;
$content_box_error_background_color: #ffe5e7;
$content_box_success_background_color: #e6ffe5;
$content_box_warning_background_color: #ffffe6;

$content_box_border_color: #e6e6e6;

$content_input_placeholder: silver;


$simple-line-font-path: "#{$rootPath}/assets/fonts/";
$fa-font-path: "#{$rootPath}/assets/fonts";
